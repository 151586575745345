var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "payment-method-selection-modal",
        "header-class": "no-borders",
        "hide-footer": "",
        size: "xl",
        title: _vm.modalTitle,
      },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _vm.paymentMethodErrors
        ? _c(
            "div",
            { staticClass: "method-failed" },
            [
              _c("banner", {
                attrs: { message: _vm.paymentMethodErrors, variant: "failed" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.achDisabledForProduct
        ? _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c("banner", {
                attrs: {
                  "can-close": true,
                  message:
                    "One or more products in your cart can not be purchased by ACH payment method",
                  variant: "warning",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "compliance-service-container" }, [
        _c("h5", [_vm._v("\n      Compliance Service\n    ")]),
        _c("div", { staticClass: "compliance-filing-fees" }, [
          _c("h6", [
            _vm._v("\n        " + _vm._s(_vm.complianceFiling) + "\n      "),
          ]),
          _c("p", [
            _vm._v(
              "\n        " + _vm._s(_vm.complianceServiceAmount) + "\n      "
            ),
          ]),
        ]),
      ]),
      _c("hr"),
      _c("select-payment-method", {
        staticClass: "m-0 p-0 border-0",
        attrs: {
          "is-billing-active": _vm.isBillingActive,
          "payment-header-text": _vm.selectPaymentMethodCardHeaderText,
        },
        on: {
          missingPaymentMethod: _vm.missingPaymentMethod,
          closeSection: _vm.closeSection,
          togglePanel: _vm.togglePanel,
          "add-method-result": _vm.paymentMethodResult,
        },
      }),
      _vm.showSubmitContainer
        ? _c(
            "div",
            { staticClass: "submit-payment-container" },
            [
              _c("p", { staticClass: "small" }, [
                _vm._v("\n      By clicking 'Submit', I accept the "),
                _c(
                  "a",
                  {
                    staticClass: "link-primary",
                    attrs: { href: "tosUrl", target: "_blank" },
                  },
                  [_vm._v("Terms and Conditions")]
                ),
              ]),
              _c(
                "button",
                {
                  class: _vm.submitButtonClass,
                  attrs: {
                    "aria-label": "Submit Payment button",
                    disabled: _vm.hasSubmitted,
                  },
                  on: { click: _vm.submitPaymentMethodSelection },
                },
                [_vm._v("\n      " + _vm._s(_vm.submitButtonText) + "\n    ")]
              ),
              _c("payment-ssl"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }